import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="City Stories" />
    <h1>EN City Stories</h1>
    <p>EN Willkommen zu den City-Stories.</p>
    <ul>
      {
        data.allMdx.nodes.map(node => (
          <li key={node.id}>
            <Link to={`en/${node.slug.toLowerCase().split('.')[0]}-en`}>{node.frontmatter.title}</Link>
          </li>
        ))
      }
      </ul>
  </Layout>
)

export const query = graphql`
  query 
  { 
    allMdx(filter: {slug: {glob: "*.en"}}) {
      nodes {
        frontmatter {
          title
        }
        id
        body
        slug
      }
    }
  }
`

export default IndexPage
